const FaqReducer = (state = {}, action) => {
   switch (action.type) {
      case 'LIST_FAQ':
         {
            return {
               ...state,
               faqPayload: action.payload
            };
         }
      default:
         return state;
   }
}

export default FaqReducer