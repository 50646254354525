import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Routes from './Routes';
import 'semantic-ui-css/semantic.min.css'
import './Resources/styles.css';
import './Resources/responsive.css';
// import './Resources/leaflet.css';
import RootReducer from './Utilities/Reducers/RootReducer';

const App = () => {
  const store = createStore(RootReducer, applyMiddleware(thunk));
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

export default App
