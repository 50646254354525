const EmployeeReducer = (state = {}, action) => {
   switch (action.type) {

      case 'LIST_ATTLOG':
         {
            return {
               ...state,
               attLogPayload: action.payload
            };
         }
      default:
         return state;
   }
}

export default EmployeeReducer