import { SAVE_CONTACT_US_URL } from '../../Providers/Api';
import axios from "axios";

// Save Action
export function createContactUsAction(values, actions) {
   return async function (dispatch) {
      // console.log('Form Data====', values)
      actions.setSubmitting(true)
      dispatch({
         type: 'MESSAGE',
         payload: false
      })
      axios({
         method: 'POST',
         url: SAVE_CONTACT_US_URL,
         data: values
      })
         .then(function (response) {
            // console.log('Product Action=================', response);
            actions.setSubmitting(false)
            if (response.data.status) {
               dispatch({
                  type: 'MESSAGE',
                  payload: response.data.message,
               })
               actions.resetForm()
            } else {
               dispatch({
                  type: 'MESSAGE',
                  payload: response.data.message,
               })
            }
            setTimeout(() => {
               dispatch({
                  type: 'MESSAGE',
                  payload: "",
               })
            }, 5000)
         })
         .catch(function (error) {
            actions.setSubmitting(false)
            dispatch({
               type: 'MESSAGE',
               payload: "Server connection failed. Ensure network connection"
            })
            console.log(error);
         });
   }
}
