import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Accordion } from 'semantic-ui-react'
import { getFaqAction } from '../../Utilities/Actions/CMS/FaqAction';

const FAQs = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getFaqAction());
  }, [dispatch])
  const faqData = useSelector(state => state.faqReducer.faqPayload);
  const panels = faqData && faqData.map((item, index) => {
    return {
      key: index,
      title: item.title,
      content: [item.description].join(' '),
    }
  });

  return (
    <div className="main-section" style={{ justifyContent: 'flex-start' }}>
      <Grid divided='vertically'>
        <h3>Frequently asked questions</h3>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <Accordion defaultActiveIndex={0} panels={panels} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default FAQs;
