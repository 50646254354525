import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import logo from '../../Resources/Images/logo.png';
import routes from '../../Resources/routes';

const HeaderApp = () => {

   return (
      <Menu pointing text className="app-menu">
         <Menu.Item>
            <Link to="/">
               <img src={logo} alt="App Logo" className="logo" />
            </Link>
         </Menu.Item>
         <Menu.Menu position='right'>
            {routes.web.map((route, index) =>
               <NavLink
                  key={index}
                  exact={route.exact}
                  to={route.Navigation}
               >
                  {route.MenuName}
               </NavLink>
            )}
            <a href="//web.appitrace.com">Login / Register</a>
         </Menu.Menu>
      </Menu>
   )
}

export default HeaderApp