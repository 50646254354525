import React from 'react';
import { Button, Icon } from 'semantic-ui-react'

const AboutUs = ({ history }) => {
  return (
    <div className="main-section">
      <div>
        <h3>Mark attendance from anywhere in the office, client place, field or home workplace.</h3>
        <p>AppiTrace was born out of our everyday’s need. No more lose your business productive time to just only mark attendance. At AppiTrace, we have our highly experienced and dedicated team, who have been continuously learning, discovering ourselves and striving to make this app simple, usable and reliable. This will boost your employees to perform at 100% of their potential. We hope you will have great experience too.</p>
        <h4>Full-fledged attendance feature in just a step away.</h4>
        <Button className="btn-custom" onClick={() => window.location.replace('//web.appitrace.com')}>
          Let's Start <Icon style={{ opacity: 1 }} name='long arrow alternate right' />
        </Button>
      </div>
    </div>
  );
}

export default AboutUs;
