const initialState = {
   openRegPayload:false,
   openOtpPayload: false,
   openPasswordPayload: false,
   groupCreateOpenPayload: false,
   changePasswordBtnPayload: false,
   editProfileNamePayload: false,
   addAddressPayload: false
}

const ButtonReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'CHANGE_PASSWORD_BTN_STATUS':
         {
            return {
               ...state,
               changePasswordBtnPayload: action.payload
            };
         }
      case 'EDIT_PROFILE_NAME_STATUS':
         {
            return {
               ...state,
               editProfileNamePayload: action.payload
            };
         }
      case 'ADD_ADDRESS_OPEN':
         {
            return {
               ...state,
               addAddressPayload: action.payload
            };
         }
      case 'ADD_ADDRESS_CLOSE':
         {
            return {
               ...state,
               addAddressPayload: action.payload
            };
         }
      case 'REG_OPEN_STATUS':
         {
            return {
               ...state,
               openRegPayload: action.payload
            };
         }
      case 'OTP_OPEN_STATUS':
         {
            return {
               ...state,
               openOtpPayload: action.payload
            };
         }
      case 'PASSWORD_OPEN_STATUS':
         {
            return {
               ...state,
               openPasswordPayload: action.payload
            };
         }
      case 'GROUP_CREATE_OPEN_STATUS':
         {
            return {
               ...state,
               groupCreateOpenPayload: action.payload
            };
         }
      default:
         return state;
   }
}

export default ButtonReducer