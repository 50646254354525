import {
   FAQ_URL,
} from '../../Providers/Api';
import axios from "axios";

export function getFaqAction() {
   return async (dispatch) => {
      axios({
         method: 'GET',
         url: FAQ_URL,
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
      }).then(function (response) {
         // console.log(response)
         if (response.status === 200) {
            if (response.data.status === true) {
               dispatch({
                  type: 'LIST_FAQ',
                  payload: response.data.data
               })
            }
         }
      }).catch(function (error) {
         dispatch({
            type: 'LOADING_STATUS',
            payload: {
               string: "faq",
               status: false
            }
         })
         dispatch({
            type: 'MESSAGE',
            payload: "Server connection failed. Ensure network connection"
         })

         console.log(error);
      })
   }
}
