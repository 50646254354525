const initial = {
   otpOpenStatusPayload: false,
   otpOpenButtonPayload: true
}

const AuthReducer = (state = initial, action) => {
   switch (action.type) {
      case 'ACCESS_TOKEN':
         {
            return {
               ...state,
               tokenPayload: action.payload
            };
         }
      case 'ACCESS_TOKEN_LOGIN':
         {
            return {
               ...state,
               tokenLoginPayload: action.payload
            };
         }
      // case 'USER_RAGISTER':
      //    {
      //       return {
      //          ...state,
      //          profilePayload: action.payload
      //       };
      //    }
      case 'USER_LOGIN':
         {
            return {
               ...state,
               profilePayload: action.payload
            };
         }
      case 'USER_ID_STATUS':
         {
            return {
               ...state,
               userIdStatusPayload: action.payload
            };
         }
      case 'OTP_STATUS':
         {
            return {
               ...state,
               otpStatusPayload: action.payload
            };
         }
      case 'PASSWORD_STATUS':
         {
            return {
               ...state,
               passwordStatusPayload: action.payload
            };
         }
      case 'USER_DATA':
         {
            return {
               ...state,
               userPayload: action.payload
            };
         }
      case 'IS_EXIST':
         {
            return {
               ...state,
               isExistPayload: action.payload
            };
         }
      case 'PASSWORD_RESET':
         {
            return {
               ...state,
               passwordResetPayload: action.payload
            };
         }
      case 'FORGOT_CONFIRM_STATUS':
         {
            return {
               ...state,
               forgotConfirmPayload: action.payload
            };
         }
      case 'FORGOT_VCODE_STATUS':
         {
            return {
               ...state,
               forgotVCodePayload: action.payload
            };
         }
      case 'FORGOT_PASSWORD_STATUS':
         {
            return {
               ...state,
               forgotPasswordPayload: action.payload
            };
         }
      default:
         return state;
   }
}

export default AuthReducer