import React from 'react';
import { Button, List, Icon } from 'semantic-ui-react'

const Home = ({ history }) => {
    return (
        <div className="main-section">
            <div>
                <p>AppiTrace provides a simple and efficient way for Employer, Teachers or anyone who wants to keep their employees or students track records of daily attendance.</p>
                <h3>A quick & easy way to record attendance through AppiTrace</h3>
                <p>We offers a quick & easy way to track office & field employee’s time & Attendance with Selfie & GPS location. </p>
                <h3>Key Features:</h3>
                <List>
                    <List.Item value='*'>
                        <List.Description>
                            <b>Fast Start:</b> Only register by your company name and add your employees. Ask them to use it.
                        </List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Description>
                            <b>Touch less:</b> Now in this pandemic (Covid-19) touch less Attendance for "Work from Home" Employees, students, or any one. Time In & Time Out & leave can apply from anytime, anywhere.
                        </List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Description>
                            <b>Manage and read attendance:</b> Manage and read attendance record of your employee from anywhere any time.
                        </List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Description>
                            <b>Reports:</b> View details reports along with time, location and selfie image from admin panel.
                        </List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Description>
                            <b>Employee:</b> Employees also will get fun to put attendance with selfie.
                        </List.Description>
                    </List.Item>
                </List>
                <h5>Why wait, you are just a click away from start, which make your work life really easy.</h5>
                <Button onClick={() => window.location.replace('//web.appitrace.com')} className="btn-custom">
                    {/* <img src={startIcon} alt="start icon" height='12' />  */}
                    Let's Start <Icon style={{ opacity: 1 }} name='long arrow alternate right' />
                </Button>
            </div>
        </div>
    );
}

export default Home;
