import React from 'react';
import { Grid, Button, Form, Input, TextArea, Divider } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { createContactUsAction } from '../../Utilities/Actions/CMS/ContactUsAction'

const ContactUs = () => {
  const dispatch = useDispatch()
  const contactUsSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name is too short!')
      .max(50, 'Name is too Long!')
      .required('Name is required'),
    contact: Yup.string()
      .min(8, 'Please provide valid email id or mobile no.')
      .max(9999, 'Please provide valid email id or mobile no.')
      .required('Please provide valid email id or mobile no.'),
    message: Yup.string()
      .min(20, 'Message content should between 20 to 1000 character')
      .max(1000, 'Message content should between 20 to 1000 character')
      .required('Please provide message')
  });

  const message = useSelector(state => state.messageReducer.messagePayload);

  // console.log(message);

  return (
    <div className="main-section">
      <Grid divided='vertically'>
        <h3>Write us for for anything</h3>
        <Grid.Row>
          <Grid.Column mobile={16} computer={8}>
            <div className="contact-box">
              {Boolean(message) && <span style={{ color: '#43beb2' }}><b>{message}</b></span>}
              <Formik
                initialValues={{
                  name: '',
                  contact: '',
                  message: ''
                }}
                validationSchema={contactUsSchema}
                onSubmit={(values, actions) => { dispatch(createContactUsAction(values, actions)) }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form>
                    <Form.Field
                      id='name'
                      control={Input}
                      label='Name'
                      placeholder='Your Name or company name'
                      autoFocus={true}
                      value={values.name}
                      onChange={handleChange('name')}
                      maxLength={50}
                      error={
                        errors.name && touched.name ?
                          {
                            content: errors.name,
                            pointing: 'below'
                          } : false}
                    />
                    <Form.Field
                      id='email'
                      control={Input}
                      label='Email / Phone Number'
                      placeholder='Email / Phone Number'
                      value={values.contact}
                      onChange={handleChange('contact')}
                      maxLength={50}
                      error={
                        errors.contact && touched.contact ?
                          {
                            content: errors.contact,
                            pointing: 'below'
                          } : false}
                    />
                    <Form.Field
                      id='message'
                      control={TextArea}
                      label='Message'
                      placeholder='Message'
                      value={values.message}
                      onChange={handleChange('message')}
                      maxLength={1000}
                      error={
                        errors.message && touched.message ?
                          {
                            content: errors.message,
                            pointing: 'below'
                          } : false}
                    />
                    <Button
                      onClick={() => handleSubmit()}
                      className="btn-custom"
                      loading={isSubmitting}>
                      Send Message
                    </Button>
                  </Form>
                )}
              </Formik >
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <React.Fragment>
              <h4>Do you have any Question?</h4>
              <p>Contact us any time, we are here to help you.</p>
              <Divider />
            </React.Fragment>
            <React.Fragment>
              <h4>Services or products queries</h4>
              <p>If you have any queries regarding AppiTrace services or products, then please write us.</p>
              <Divider />
            </React.Fragment>
            <React.Fragment>
              <h4>Technical suppurt</h4>
              <p>Please don’t hesitate to write us for any issue if you have found. It’s will help us to make a more stronger application which we allows expect.</p>
              <Divider />
            </React.Fragment>
            <React.Fragment>
              <h4>Application betterment suggestion</h4>
              <p>Suggestions and feedbacks are allows welcome.</p>
              <Divider />
            </React.Fragment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div >
  );
}

export default ContactUs;
