const CompanyReducer = (state = {}, action) => {
   switch (action.type) {

      case 'COMPANY_GET':
         {
            return {
               ...state,
               companyPayload: action.payload
            };
         }
      case 'COMPANY_MODAL':
         {
            return {
               ...state,
               companyModalPayload: action.payload
            };
         }
      default:
         return state;
   }
}

export default CompanyReducer