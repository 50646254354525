const GroupReducer = (state = {}, action) => {
   switch (action.type) {

      case 'LIST_GROUP':
         {
            return {
               ...state,
               groupPayload: action.payload
            };
         }
      case 'CREATE_GROUP': {
         const addedData = action.payload;
         const clone = JSON.parse(JSON.stringify(state.groupPayload));
         clone.unshift(addedData);
         return { ...state, groupPayload: clone };
      }
      case 'DELETE_GROUP': {
         const _id = action.payload;
         const clone = JSON.parse(JSON.stringify(state.groupPayload));
         const index = clone.findIndex((obj) => obj._id === _id);
         // console.log("Red", index);
         if (index !== -1) clone.splice(index, 1);
         return { ...state, groupPayload: clone };
      }
      case 'UPDATE_GROUP': {
         const updateClient = action.payload;
         const clone = JSON.parse(JSON.stringify(state.groupPayload));
         const index = clone.findIndex((obj) => obj._id === updateClient._id);
         if (index !== -1) clone[index] = updateClient;
         return { ...state, groupPayload: clone };
      }
      default:
         return state;
   }
}
export default  GroupReducer