import {combineReducers} from 'redux';
import LoadingReducer from './LoadingReducer';
import AuthReducer from './AuthReducer';
import GroupReducer from './GroupReducer';
import EmployeeReducer from './EmployeeReducer';
import CompanyReducer from './CompanyReducer';
import AttLogReducer from './AttLogReducer';
import FaqReducer from './FaqReducer';
import MessageReducer from './MessageReducer';
import ButtonReducer from './ButtonReducer';

const RootReducer = combineReducers({
     loadingReducer:LoadingReducer,
     authReducer:AuthReducer,
     groupReducer:GroupReducer,
     employeeReducer:EmployeeReducer,
     companyReducer:CompanyReducer,
     attLogReducer:AttLogReducer,
     faqReducer:FaqReducer,
     messageReducer:MessageReducer,
     buttonReducer:ButtonReducer,
})

export default RootReducer;