import React from 'react';
import { Sidebar, Menu } from 'semantic-ui-react';
import routes from '../../Resources/routes';
import { NavLink } from 'react-router-dom';

const SiteSideBar = (props) => {
   return (
      <Sidebar
         className="mobile-sidebar"
         as={Menu}
         animation='overlay'
         icon='labeled'
         inverted
         onHide={props.onHide}
         vertical
         visible={props.visible}
         width='thin'
      >
         {routes.web.map((route, index) =>
            <Menu.Item
               key={index}
               onClick={props.onHide}>
               <NavLink
                  exact={route.exact}
                  to={route.Navigation}
               >
                  {route.MenuName}
               </NavLink>
            </Menu.Item>
         )}
      </Sidebar>
   )
}

export default SiteSideBar