import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react'
import logo from '../../Resources/Images/logo.png';

const MobileHeader = (props) => {
   const history = useHistory()

   // const [supportsPWA, setSupportsPWA] = useState(false);
   const [promptInstall, setPromptInstall] = useState(null);

   useEffect(() => {
      const handler = e => {
         e.preventDefault();
         console.log("we are being triggered :D");
         // setSupportsPWA(true);
         setPromptInstall(e);
      };
      window.addEventListener("beforeinstallprompt", handler);

      return () => window.removeEventListener("transitionend", handler);
   }, []);

   const handleInstallClick = evt => {
      evt.preventDefault();
      if (!promptInstall) {
         return;
      }
      promptInstall.prompt();
   };
   // if (!supportsPWA) {
   //    return null;
   // }

   return (
      <Menu className="mobile-menu">
         <Menu.Item onClick={props.onClick}>
            <Icon name="bars" size='large' />
         </Menu.Item>
         <img src={logo} alt="App Logo" className="mobile-logo" onClick={() => history.push('/')} />
         <Menu.Menu position='right'>
            <Menu.Item onClick={handleInstallClick} className="menu-text">
               <Icon name="download" />
            </Menu.Item>
            <Menu.Item as={Link} to="/login" className="menu-text">
               Login/Register
        </Menu.Item>
         </Menu.Menu>
      </Menu>
   )
}

export default MobileHeader