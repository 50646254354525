import Home from '../Pages/Web/Home';
import AboutUs from '../Pages/Web/AboutUs';
import ContactUs from '../Pages/Web/ContactUs';
import FAQs from '../Pages/Web/FAQs';
import TnC from '../Pages/Web/TnC';
import PrivacyPolicy from '../Pages/Web/PrivacyPolicy';

const routes = {
    web: [
        { Navigation: '/', exact: true, MenuName: 'Home', component: Home },
        { Navigation: '/about-us', exact: false, MenuName: 'About Us', component: AboutUs },
        { Navigation: '/contact-us', exact: false, MenuName: 'Contact Us', component: ContactUs },
        { Navigation: '/faqs', exact: false, MenuName: 'FAQs', component: FAQs },
        // { Navigation: '//web.appitrace.com', exact: false, MenuName: 'Login / Register', component: Login },
        // { Navigation: '*', exact: false, component: Page404 },
    ],
    staticWeb: [
        { Navigation: '/tnc', exact: false, MenuName: 'TnC', component: TnC },
        { Navigation: '/privacy-policy', exact: false, MenuName: 'Privacy Policy', component: PrivacyPolicy }
    ]
}


export default routes;