import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import HeaderApp from './HeaderApp';
import app1 from '../../Resources/Images/app1.png';
import playStore from '../../Resources/Images/play-store-logo.png';
import MobileSideBar from './MobileSideBar';
import getWindowDimensions from '../Dimensions';
import MobileHeader from './MobileHeader';

const LayoutApp = ({ children }) => {
	// console.log('App LayoutApp')
	const { width } = getWindowDimensions();
	const [barVisible, setBarVisible] = React.useState(false)

	return (
		<div className="container-fluid">
			<Grid className="main">
				{width < 767 && <MobileHeader onClick={() => setBarVisible(!barVisible)} />}
				<Grid.Row className="row">
					<Grid.Column mobile={16} computer={6} className="silid-container">
						<div className="item-container">
							<a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=web.appitrace.com">
								<img src={playStore} alt="play store" className="play-store-image" />
							</a>
							<div className="main-container-left">
								<img src={app1} alt="Mobile 1" className="app-image" />
							</div>
							<div className="footer">
								<Link className="footer-link" to="/tnc">Terms and Conditions</Link>
								<span>|</span>
								<Link className="footer-link" to="/privacy-policy">Privacy Policy</Link><br />
								<span>© {new Date().getYear()} AppiTrace, all rights reserved</span>
							</div>
						</div>
					</Grid.Column>
					<Grid.Column mobile={16} computer={10} className="light-container">
						<div className="item-container">
							{width > 780 ?
								<HeaderApp /> :
								<MobileSideBar visible={barVisible} onHide={() => setBarVisible(false)} />}
							<div className="main-container-right">
								{children}
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
				{width < 780 &&
					<div className="mobile-footer">
						<Link className="footer-link" to="/tnc">Terms and Conditions</Link>
						<span>|</span>
						<Link className="footer-link" to="/privacy-policy">Privacy Policy</Link><br />
						<span>© {new Date().getYear()} AppiTrace, all rights reserved</span>
					</div>}
			</Grid>
		</div>
	);
}

export default LayoutApp;
