import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory
} from "react-router-dom";
import routes from '../Resources/routes';
import LayoutApp from '../Components/Layout/LayoutApp';
const Routes = () => {

	const history = useHistory()
	const cmsPath = [...routes.web, ...routes.staticWeb]
	const exactPath = cmsPath.map((route) => {
		return route.Navigation
	})

	return (
		<Router history={history}>
			<Switch>
				<Route exact path={exactPath}>
					<LayoutApp>
						<Switch>
							{routes.web.map((route, index) =>
								<Route
									key={index}
									path={route.Navigation}
									exact={route.exact}
									component={route.component} />
							)}
							{routes.staticWeb.map((route, index) =>
								<Route
									key={index}
									path={route.Navigation}
									exact={route.exact}
									component={route.component} />
							)}
						</Switch>
					</LayoutApp>
				</Route>
			</Switch>
		</Router>
	);
}

export default Routes;
