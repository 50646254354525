const EmployeeReducer = (state = {}, action) => {
   switch (action.type) {

      case 'LIST_EMPLOYEE':
         {
            return {
               ...state,
               employeePayload: action.payload
            };
         }
      case 'CREATE_EMPLOYEE': {
         const addedData = action.payload;
         const clone = JSON.parse(JSON.stringify(state.employeePayload));
         clone.unshift(addedData);
         return { ...state, employeePayload: clone };
      }
      case 'DELETE_EMPLOYEE': {
         const _id = action.payload;
         const clone = JSON.parse(JSON.stringify(state.employeePayload));
         const index = clone.findIndex((obj) => obj._id === _id);
         if (index !== -1) clone.splice(index, 1);
         return { ...state, employeePayload: clone };
      }
      case 'UPDATE_EMPLOYEE': {
         const updateEmployee = action.payload;
         const clone = JSON.parse(JSON.stringify(state.employeePayload));
         const index = clone.findIndex((obj) => obj._id === updateEmployee._id);
         if (index !== -1) clone[index] = updateEmployee;
         return { ...state, employeePayload: clone };
      }
      default:
         return state;
   }
}

export default EmployeeReducer