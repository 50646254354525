import React from 'react';
import {Link} from 'react-router-dom'
const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <div className="main-section">
                <div class="scrollable-container">
                    <h1><u>Privacy Policy</u></h1>
                    <p>At AppiTrace, (“we,”“our, “or“us”) gather your personal data as well as
                        process it. Our promise is to protect your privacy. This page elucidates
                        how your personal information is gathered, utilized, and revealed by us.
                        The policy applies to our web app, our service, and together with our
                        mobile app AppiTrace. When you access our site or use our service, you
                        consent that you have fully read, understood, and approve the collection,
                        use, storage, as well as revelation of your data.</p>
                    <p><strong>What Data Do We Gather</strong></p>
                    <p>The personal data that we gather when you’re our customer, providing it to
                        AppiTrace include:</p>
                    <ul>
                        <li>Name or User Name</li>
                        <li>Password</li>
                        <li>Billing address</li>
                        <li>Shipping address</li>
                        <li>Email address</li>
                        <li>Telephone number</li>
                        <li>Demographic information about you</li>
                        <li>Job Titles</li>
                    </ul>
                    <p><strong>Information are collected while using the application:</strong></p>
                    <p>While using our application, you can choose to allow us to collect certain information in order to provide you with a more personalized experience.</p>
                    <ul>
                        <li>
                        <strong>Information regarding your location:</strong> This includes precise location data from your device's GPS, Wi-Fi, or cellular network, as well as approximate location data such as your IP address. We use this information to provide location-based features of Our Service, such as finding nearby businesses or events, and to personalize the Service based on your location. We may also use location data for analytics and advertising purposes, but we will always ask for your permission before doing so. We may share location data with third-party service providers who assist us in providing the Service, but we will not sell or rent your location data to third parties for their own marketing purposes.
                        </li>
                        <li>
                        <strong>Pictures and other information from your Device's camera and photo library:</strong> We may access the camera and photo library on your device to allow you to take photos within the app or to upload photos to the Service. We may also analyze photos using automated technologies to provide features such as object recognition or image search. We may store photos on our servers or on third-party service providers' servers, but we will not share your photos with third parties for their own marketing purposes.
                        </li>
                    </ul>
                    <p>By utilizing this information, we are able to enhance the functionality of our service, optimize it to better meet the needs of our users, and provide a more seamless user experience.</p>
                    <p>To give you greater control over your privacy, our application allows you to easily enable or disable access to this information at any time, simply by adjusting your device settings.</p>

                    <p><strong>How We Use the Collected Data</strong></p>
                    <p>The data we collate is used in these ways:</p>
                    <ul>
                        <li>Use personal information in line with our privacy policy</li>
                        <li>To give you access to our services, answer your queries, resolve your issues, and process your transactions
                        </li>
                        <li>Help us improve our services, and content, develop new
                            products/services, and understand users</li>
                        <li>Contact you to educate about updates, services, events, and promotions</li>
                    </ul>
                    <p><strong>Data We Gather Automatically</strong></p>
                    <p>The kind of information we collect automatically is:</p>
                    <ul>
                        <li>Log data</li>
                        <li>Cookies</li>
                        <li>Analytics information</li>
                        <li>Single sign-on service</li>
                    </ul>
                    <p><strong>When Do We Collect Data from Third Parties</strong></p>
                    <p>When you provide your email address to us showing interest to be our
                        customer, we get information from third parties providing automatic fraud
                        discovery services to our company. We sometimes collect data that is public
                        like on social media platforms.</p>
                    <p><strong>Sharing Information</strong></p>
                    <p>We share your personal information with other businesses or people only in
                        limited scenarios. These include:</p>
                    <p><strong>Related entities</strong>: Provide information to third parties, our subsidiaries, and allied
                        businesses for reasons reliable with our privacy policy.</p>
                    <p><strong>Unrestricted data</strong>: Any kind of information that you willingly indicate to incorporate in a
                        public area of service or for that matter share with AppiTrace via other
                        ways. Such information will be regarded as unsolicited and
                        non-confidential.</p>
                    <p><strong>Legal necessities</strong>: Provide information when we believe that its use, access, preservation,
                        or revelation is required to meet applicable:</p>
                    <ul>
                        <li>Law</li>
                        <li>Rules</li>
                        <li>Legal procedures</li>
                        <li>Enforceable governmental requests</li>
                        <li>Terms of service</li>
                    </ul>
                    <p>Such information could also be used to recognize and prevent fraud,
                        technical challenges, and protect against imminent damage to rights,
                        AppiTrace property, and its users as well as the public.</p>
                    <p><strong>Third-Party Links</strong></p>
                    <p> Our website includes links to web pages owned and used by third parties and
                        so we encourage users to read their privacy policies as well.</p>
                    <p><strong>Children’s Privacy</strong></p>
                    <p>We do not intentionally gather data from anyone below 18 years old. So, if
                        you are providing information via our website, it means you are more than
                        18 years.</p>
                    <p><strong>Changes to Our Privacy Policy</strong></p>
                    <p>We regularly review our compliance and reserve the right to modify or
                        update our privacy policy at any time. We’ll notify all site users by
                        including our updated privacy policy on this web page.</p>
                    <p><strong>GDPR</strong></p>
                    <p>It’s an EU-based data protection and privacy law controlling how EU people’s personal information is secured by
                        businesses.</p>
                    <p><strong>Data Retention</strong></p>
                    <p> We retain your data as required to conform to our legal responsibilities
                        for litigation reasons, marketing, and managing financial records,
                        resolving disputes, and enforcing agreements.</p>
                    <h3><u>Contact Us</u></h3>
                    <p>Don't hesitate to contact us if you have any questions.</p>
                    <ul>
                        <li>Via Email: <a href="mailto:contact@appitrace.com">contact@appitrace.com</a></li>
                        <li>Via this Link: <Link to="/contact-us">https://appitrace.com/contact-us</Link></li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}
export default PrivacyPolicy;